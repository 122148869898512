<template>
  <div>
    <b-modal
      v-model="showModal"
      modal
      :title="
        mode == 'paid' ? 'COURT INFO PAYMENTS' : 'COURT INFO PENDING PAYMENTS'
      "
      modal-class="modal-primary"
      hide-footer
      size="xls"
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="$emit('close')"
    >
      <b-row>
        <b-col cols="12">
          <filter-slot
            :filter="FilterSlot.filter"
            :totalRows="FilterSlot.totalRows"
            :paginate="FilterSlot.paginate"
            :startPage="FilterSlot.startPage"
            :toPage="FilterSlot.toPage"
            :filterPrincipal="FilterSlot.filterPrincipal"
            @reload="$refs['refAmountDetailCI'].refresh()"
          >
            <b-table
              slot="table"
              ref="refAmountDetailCI"
              no-border-collapse
              class="position-relative"
              :fields="fields"
              show-empty
              no-provider-filtering
              sticky-header="50vh"
              primary-key="id"
              responsive="sm"
              :items="CiProvider"
              :busy.sync="isBusy"
              :per-page="FilterSlot.paginate.perPage"
              :current-page="FilterSlot.paginate.currentPage"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>

              <template #cell(case_number)="data">
                <strong>{{ data.item.case_number }}</strong>
              </template>

              <template #cell(advisor)="data">
                <strong v-if="data.item.advisor">{{
                  data.item.advisor
                }}</strong>
                <b-badge v-else variant="light-warning"> PENDING</b-badge>
              </template>

              <template #cell(document)="data">
                <span> {{ data.item.document }} </span> <br />
                <b-badge
                  :variant="
                    data.item.service_id == 1
                      ? 'light-primary'
                      : 'light-warning'
                  "
                  >{{ data.item.service }}</b-badge
                >
              </template>

              <template #cell(amount)="data">
                <div class="text-success">
                  $ {{ data.item.amount | currencyZero }}
                </div>
              </template>

              <template #cell(created_at)="data">
                <div>{{ data.item.created_at | myGlobalDay }}</div>
              </template>

              <template #cell(module_name)="data">
                <div>
                  <b-badge
                    v-b-tooltip.hover.top="data.item.name_module"
                    :style="'background:' + data.item.color_module"
                  >
                    {{ data.item.module_name }}
                  </b-badge>
                </div>
              </template>
              <template #cell(actions)="data">
                <feather-icon
                  icon="CreditCardIcon"
                  size="16"
                  class="text-primary clickable"
                  @click="openPayModal(data.item)"
                />
              </template>
            </b-table>
          </filter-slot>
        </b-col>
      </b-row>
    </b-modal>
    <!--  -->
    <CiPayModal
      v-if="showPayModal"
      :pendingPayment="pendingPaymentSelected"
      @close="showPayModal = false"
      @reload="
        $refs['refAmountDetailCI'].refresh(),
          (showPayModal = false),
          $emit('closeWithRefresh')
      "
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import CiPayModal from "@/views/debt-solution/views/court-info/components/modals/CiPayModal.vue";

export default {
  props: {
    tab: {
      type: Number,
    },
    // paid - pending
    mode: {
      type: String,
    },
  },
  components: {
    CiPayModal,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  data() {
    return {
      isBusy: false,
      showModal: true,
      showPayModal: false,
      pendingPaymentSelected: {},
      fields: [
        { key: "case_number", label: "Case Number", sortable: false },
        { key: "module_name", label: "Module", sortable: false },
        { key: "advisor", label: "Assigned", sortable: false },
        { key: "document", label: "Document", sortable: false },
        { key: "amount", label: "Amount", sortable: false },
        { key: "created_at", label: "Created at", sortable: false },
      ],
      FilterSlot: {
        filter: [],
        totalRows: 0,
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        startPage: 0,
        toPage: 0,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Enter case number...",
          model: "",
        },
      },
    };
  },
  created() {
    if (this.mode == "pending") {
      this.fields.push({ key: "actions", label: "Actions", sortable: false });
    }
  },
  methods: {
    async getCourtCase(caseId) {
      try {
        this.addPreloader();
        const data = await CourtInfoService.getCourtCase({
          case_id: caseId,
        });
        return data[0] || [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async CiProvider() {
      this.isBusy = true;
      const params = {
        tab: this.tab,
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
        search_text: this.FilterSlot.filterPrincipal.model,
        page: this.FilterSlot.paginate.currentPage,
        perPage: this.FilterSlot.paginate.perPage,
        mode: this.mode,
      };
      const { data, status } = await CourtInfoService.getTotalAmountCiDetail(
        params
      );
      if (status === 200) {
        this.items = data.data;
        this.FilterSlot.totalRows = data.total;
        this.FilterSlot.startPage = data.from ? data.from : 0;
        this.FilterSlot.toPage = data.to ? data.to : 0;
        this.FilterSlot.paginate.currentPage = data.current_page;
        this.FilterSlot.paginate.perPage = data.per_page;
        return this.items || [];
      }
      this.isBusy = false;
    },
    async openModalFilesCases(caseId) {
      this.caseId = caseId;
      this.modalViewDocumentsShow = true;
    },
    getVariant(status) {
      switch (status) {
        case "PENDING":
          return "light-warning";
        case "REVISION":
          return "light-secondary";
        case "APPROVED":
          return "light-primary";
        case "CANCELLED":
        case "REJECTED":
          return "light-danger";
        case "DONE":
          return "light-success";
        case "CLOSED":
          return "light-info";
      }
    },

    async openPayModal(item) {
      const courtCase = await this.getCourtCase(item.case_id);
      this.pendingPaymentSelected.case_type_document_id =
        item.case_type_document_id;
      this.pendingPaymentSelected.pending_amount = item.amount;
      this.pendingPaymentSelected.amount = item.amount;
      this.pendingPaymentSelected.caseId = item.case_id;
      this.pendingPaymentSelected.lead_id = courtCase.lead_id;
      this.pendingPaymentSelected.modul_id = courtCase.modul_id;
      this.pendingPaymentSelected.account_id = courtCase.account_id;
      this.pendingPaymentSelected.account = courtCase.account;
      this.showPayModal = true;
    },
  },
};
</script>