export default [
  {
    key: "name_lead",
    label: "client",
    class: "text-left client-name",
    visible: true,
  },
  {
    key: "attorney_name",
    label: "Case",
    class: "text-left",
    visible: true,
  },
  {
    key: "document_date",
    label: "Document",
    class: "text-left",
    visible: true,
  },
  {
    key: "status",
    label: "Status",
    class: "text-left",
    visible: true,
  },
  {
    key: "id",
    label: "Timeline",
    class: "text-left",
    visible: true,
  },
  {
    key: "assigned_to",
    label: "assigned",
    class: "text-left",
    visible: true,
  },
  {
    key: "last_note",
    label: "Last Tracking",
    class: "text-center",
    visible: true,
    thStyle: { width: "250px" },
  },
  {
    key: "created_by",
    label: "created by",
    class: "text-left",
    visible: true,
  },
  {
    key: "actions",
    label: "Actions",
    tdStyle: "vertical-align: middle !important;",
    thStyle: { width: "10%" },
    visible: true,
  },
];
