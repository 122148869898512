<template>
  <b-modal
    ref="modal-assing-case"
    :title="`AVAILABILITY OF ` + itemCase.name_lead"
    title-tag="h3"
    size="md"
    modal-class="modal-primary"
    hide-footer
    @hidden="close"
  >
    <b-container v-if="itemCase.available_days.length > 0">
      <b-row>
        <b-col
          sm="4"
          v-for="(days, index) in itemCase.available_days"
          :key="index"
          class="p-2"
        >
          <b-card
            header-bg-variant="info"
            class="border h-100"
            header-class="p-0"
            no-body
          >
            <template #header>
              <span
                style="margin: 5px; margin-left: 10px"
                class="font-weigth-bolder text-white text-uppercase"
              >
                <feather-icon icon="CalendarIcon" size="15" class="mr-1" />
                {{ days.name }}
              </span>
            </template>
            <div class="mt-1">
              <!-- SCHEDULES -->

              <b-row class="icon-min">
                <b-col md="12" class="m-0 pr-0">
                  <feather-icon icon="ClockIcon" class="text-success mr-1" />
                  <small>{{ itemCase.available_hour_from | myTime }}</small>
                  <small> - </small>
                  <small>{{
                    itemCase.available_hour_to | myTimeOrNothing
                  }}</small>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container v-else class="my-5"
      ><b-row>
        <b-col class="text-center">
          <h4 class="text-info">No available days configured</h4>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
export default {
  props: {
    itemCase: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // users: [],
    };
  },
  computed: {
    //
  },
  async mounted() {
    this.toggleModal("modal-assing-case");
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>