export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    },
    cols: 6,
    visible: true
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    },
    cols: 6,
    visible: true
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Last Tracking From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    },
    cols: 6,
    visible: true
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Last Tracking To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    },
    cols: 6,
    visible: true
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Type Services",
    model: null,
    options: [],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: true
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Type Documents",
    model: null,
    options: [],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: false
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Assigned",
    model: null,
    options: [],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: true
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status",
    model: null,
    options: [
      {
        value: "0",
        label: "All"
      },
      {
        value: "1",
        label: "Pending"
      },
      {
        value: "2",
        label: "Revision"
      }
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: true
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Modules",
    model: null,
    options: [],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: false
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Created By",
    model: null,
    options: [],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: true
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Steps",
    model: null,
    options: [],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: true
  },


]
