<template>
  <b-modal
    v-model="showModal"
    ref="modal-ci-pending-payments"
    title="Payments"
    title-tag="h3"
    size="xmd"
    modal-class="modal-primary"
    hide-footer
    no-close-on-backdrop
    @hidden="close"
  >
    <template #modal-title>
      <span>
        PENDING PAYMENTS - CASE:
        <strong>{{ caseInfo.case_number }}</strong></span
      >
    </template>

    <b-row class="my-1">
      <b-col cols="12" md="6">
        <span>
          {{ caseInfo.is_lead == 1 ? "LEAD" : "CLIENT" }}
        </span>
        <div class="border border-primary rounded">
          <p
            class="text-primary d-flex align-items-center justify-content-center my-1s"
            style="gap: 5px"
          >
            {{ caseInfo.name_lead }}
          </p>
        </div>
      </b-col>
      <b-col cols="12" md="6">
        <span>ACCOUNT</span>
        <div class="border border-primary rounded">
          <p
            class="text-primary d-flex align-items-center justify-content-center my-1s"
            style="gap: 5px"
          >
            {{ caseInfo.is_lead == 1 ? "-" : caseInfo.account }}
          </p>
        </div>
      </b-col>
    </b-row>

    <b-table
      ref="trackingTable"
      class="blue-scrollbar"
      primary-key="id"
      empty-text="No matching records found"
      responsive="md"
      sticky-header="50vh"
      small
      show-empty
      no-provider-filtering
      :fields="fields"
      :items="payments"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <template #cell(document_name)="row">
        <span class="text-primary">{{ row.value }}</span>
      </template>

      <template #cell(total_cost)="row">
        <span class="text-success">${{ row.value | currencyTwo }}</span>
      </template>

      <template #cell(pending_amount)="row">
        <span class="text-warning">${{ row.value | currencyTwo }}</span>
      </template>

      <template #cell(payed_at)="row">
        <span class="text-primary">{{ row.value | myGlobalDay }}</span>
      </template>

      <template #cell(actions)="row">
        <feather-icon
          icon="CreditCardIcon"
          size="16"
          class="text-primary clickable"
          @click="openPayModal(row.item)"
        />
      </template>
    </b-table>

    <CiPayModal
      v-if="showPayModal"
      :pendingPayment="pendingPaymentSelected"
      @close="showPayModal = false"
      @reload="
        getCasePendingPayments,
          (showPayModal = false),
          $emit('closeWithRefresh')
      "
    />
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import CiPayModal from "@/views/debt-solution/views/court-info/components/modals/CiPayModal.vue";

export default {
  props: {
    caseId: {
      type: Number,
      default: null,
    },
  },
  components: {
    CiPayModal,
  },
  data() {
    return {
      caseInfo: {},
      pendingPaymentSelected: {},
      showModal: true,
      showPayModal: false,
      payments: [],
      isBusy: false,
      fields: [
        { key: "document_name", label: "Document", sortable: false },
        { key: "total_cost", label: "Total Cost", sortable: false },
        { key: "pending_amount", label: "Pending Amount", sortable: false },
        { key: "payed_at", label: "Created AT", sortable: false },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.getCourtCase();
  },
  async mounted() {
    this.getCasePendingPayments();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getCasePendingPayments() {
      try {
        const response = await CourtInfoService.getCasePendingPayments({
          case_id: this.caseId,
        });
        this.payments = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCourtCase() {
      try {
        this.addPreloader();
        const data = await CourtInfoService.getCourtCase({
          case_id: this.caseId,
        });
        this.caseInfo = data[0];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    openPayModal(pendingPaymentSelected) {
      this.pendingPaymentSelected = pendingPaymentSelected;
      this.pendingPaymentSelected.caseId = this.caseId;
      this.pendingPaymentSelected.lead_id = this.caseInfo.lead_id;
      this.pendingPaymentSelected.modul_id = this.caseInfo.modul_id;
      this.pendingPaymentSelected.account_id = this.caseInfo.account_id;
      this.pendingPaymentSelected.account = this.caseInfo.account;
      this.showPayModal = true;
    },
  },
};
</script>