<template>
  <b-modal
    v-model="showModal"
    ref="modal-ci-pending-payments"
    title="Payments"
    title-tag="h3"
    size="md"
    modal-class="modal-primary"
    hide-footer
    no-close-on-backdrop
    @hidden="close"
  >
    <ValidationObserver
      ref="formPayValidation"
      lazy
      v-bind:vid="'form-payment'"
    >
      <b-row>
        <b-col cols="4">
          <div>
            <ValidationProvider
              name="price"
              rules="required"
              v-slot="{ errors }"
            >
              <h5 class="text-left">Price</h5>
              <div
                class="rounded border-secondary font-medium-1 text-center pricesdiv"
                :class="{ 'border-danger': errors[0] }"
              >
                <b-form-radio-group
                  v-model="amount"
                  :options="optionsAmount"
                  name="radio-validation"
                  style="padding: 6px 0px"
                />
              </div>
            </ValidationProvider>
          </div>
        </b-col>

        <b-col
          cols="4"
          offset="4"
          class="d-flex justify-content-center align-items-end"
        >
          <b-button
            class="float-right"
            variant="outline-success ml-auto cursor-pointer"
            v-b-tooltip.hover.left="'ADD CARD'"
            @click="openCreateCreditCard()"
          >
            <feather-icon
              class="text-success"
              icon="CreditCardIcon"
              size="15"
            />
          </b-button>
        </b-col>

        <b-col cols="12" class="mt-1">
          <h5 class="text-left mb-0">Cards</h5>
          <credit-card-table
            :cards="cards"
            :optionName="amount"
            @cardId="cardId"
            :usedCard="usedCard"
            :disabledCards="cardsToDisable"
            ref="creditCardTable"
          />
        </b-col>

        <!-- float right button pay  -->
        <b-col cols="12" class="mt-1">
          <div class="d-flex justify-content-end">
            <b-button
              :disabled="!amount || !card"
              class="float-right"
              variant="outline-success ml-auto cursor-pointer"
              v-b-tooltip.hover.left="'PAY'"
              @click="pay()"
            >
              <feather-icon
                class="text-success"
                icon="CheckCircleIcon"
                size="15"
              />
              PAY
            </b-button>
          </div>
        </b-col>
      </b-row>
    </ValidationObserver>

    <CreateCreditCard
      v-if="createCreditOn"
      :lead="pendingPayment.lead_id"
      @reload="refreshCards"
      @close="closeCreateCreditCard"
    />
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import CreateCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue";
import CreditCardTable from "@/views/commons/components/ncr-programs/components/table/card/CreditCardTable.vue";
import VMoney from "v-money";

export default {
  props: {
    pendingPayment: {
      type: Object,
      required: true,
    },
  },
  components: {
    CreateCreditCard,
    CreditCardTable,
    VMoney,
  },
  directives: { money: VMoney },

  data() {
    return {
      showModal: true,
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      card: null,
      amount: null,
      createCreditOn: false,
      vmoneyValidate: false,
      validationMinAmount: 0,
      optionsAmount: [
        {
          value: this.pendingPayment.pending_amount,
          text: this.pendingPayment.pending_amount,
          fractionType: "multiple",
        },
      ],
      cardsToDisable: [],
      cards: [],
      usedCard: null,
      modulId: null,
      dataClient: [],
    };
  },
  computed: {
    // moduleId() {
    //   return this.$route.matched[0].meta.module;
    // },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async mounted() {
    await this.getClientOrLeadData();
    this.amount = this.pendingPayment.pending_amount;
  },
  methods: {
    async pay() {
      try {
        this.vmoneyValidate = true;
        const compPay = this.$refs.formPayValidation;
        const isValidPay = await compPay.validate();
        if (!isValidPay) {
          return;
        }

        this.addPreloader();
        let formData = new FormData();

        formData.append("modul_id", this.pendingPayment.modul_id);
        formData.append("lead_id", this.pendingPayment.lead_id);
        formData.append("user_id", this.currentUser.user_id);
        formData.append(
          "account",
          this.pendingPayment.account ? this.pendingPayment.account : ""
        );
        formData.append("amount", this.pendingPayment.pending_amount);
        formData.append("fraction_type", "multiple");
        formData.append("card_id", this.usedCard ?? this.card);
        formData.append("document_name", this.pendingPayment.document_name);
        formData.append(
          "ci_transaction_id",
          this.pendingPayment.ci_transaction_id
        );
        formData.append(
          "case_type_document_id",
          this.pendingPayment.case_type_document_id
        );
        formData.append("client_account_id", this.pendingPayment.account_id);

        const { data } = await CourtInfoService.CiPayPendingPayment(formData);
        if (data.status == "success") {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckCircleIcon",
            "Payment made successfully"
          );
        }
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          this.getInternalErrors(error) || "Something went wrong"
        );
      } finally {
        this.removePreloader();
        this.$emit("reload");
      }
    },
    closeCreateCreditCard() {
      this.createCreditOn = false;
    },
    openCreateCreditCard() {
      this.createCreditOn = true;
    },
    cardId(value) {
      this.card = value.id;
    },
    close() {
      this.$emit("close");
    },
    async getClientOrLeadData() {
      try {
        const response = await CourtInfoService.getDataClientById({
          lead_id: this.pendingPayment.lead_id,
          module_id: this.pendingPayment.modul_id,
        });
        if (response.length > 0) {
          this.dataClient = response[0];
          this.cards = JSON.parse(response[0].cards);
        } else {
          this.dataClient = null;
        }
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with search!"
        );
      }
    },
    async refreshCards() {
      this.getClientOrLeadData();
    },
  },
};
</script>