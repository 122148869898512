<template>
  <div>
    <filter-slot
      :filter="visibleFilters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable"
    >
      <template #buttons v-if="!isGlobalSearch">
        <div class="ml-2 d-flex" v-if="moduleId === 5">
          <download-formats></download-formats>
          <div
            @click="
              (showTotalAmountDetailCiModal = true), (detailMode = 'paid')
            "
            class="ml-1 cursor-pointer"
            v-b-tooltip.hover
            title="Sum of all court cases payments"
          >
            <div><b-badge variant="light-primary">Total Payments</b-badge></div>
            <div class="d-flex justify-content-center">
              <b-badge variant="light-primary">
                $ {{ totalAmount | currencyTwo }}
              </b-badge>
            </div>
          </div>
          <div
            @click="
              (showTotalAmountDetailCiModal = true), (detailMode = 'pending')
            "
            class="ml-1 cursor-pointer"
            v-b-tooltip.hover
            title="Sum of all court cases pending payments"
          >
            <div>
              <b-badge variant="light-warning">Total Pending Payments</b-badge>
            </div>
            <div class="d-flex justify-content-center">
              <b-badge variant="light-warning">
                $ {{ totalPendingAmount | currencyTwo }}
              </b-badge>
            </div>
          </div>
        </div>
      </template>
      <template #table>
        <b-table
          ref="refSearchCourtInfo"
          no-border-collapse
          class="position-relative ci-table"
          :fields="visibleFields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="CiProvider"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(name_lead)="data">
            <div
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <template v-if="data.item.module_id == moduleId">
                <div class="flex-inline">
                  <router-link
                    target="_blank"
                    :class="[textLink]"
                    v-if="data.item.client_account_id"
                    :to="{
                      name: dashboardRouteName,
                      params: { idClient: data.item.client_account_id },
                    }"
                  >
                    {{ data.item.name_lead }}
                  </router-link>
                  <router-link
                    v-else
                    :class="[textLink]"
                    target="_blank"
                    :to="{
                      name: dashboardLeadRouteName,
                      params: { id: data.item.lead_id },
                    }"
                  >
                    {{ data.item.name_lead }}
                    <br />
                    <b-badge variant="light-primary">{{
                      data.item.name_module
                    }}</b-badge>
                  </router-link>

                  <feather-icon
                    @click="openModalAvailableDays(data.item)"
                    v-b-tooltip.hover.top="`Open Available Days`"
                    icon="ClockIcon"
                    class="ml-1 text-success cursor-pointer"
                    size="17"
                    v-if="data.item.available_always == 0"
                  />

                  <feather-icon
                    v-b-tooltip.hover.top="`Available always`"
                    icon="ClockIcon"
                    class="ml-1 text-success cursor-info"
                    size="17"
                    v-else
                  />
                </div>
              </template>

              <span v-else>
                {{ data.item.name_lead }}

                <feather-icon
                  @click="openModalAvailableDays(data.item)"
                  v-b-tooltip.hover.top="`Open Available Days`"
                  icon="ClockIcon"
                  class="ml-1 text-success cursor-pointer"
                  size="17"
                  v-if="data.item.available_always == 0"
                />

                <feather-icon
                  v-b-tooltip.hover.top="`Available always`"
                  icon="ClockIcon"
                  class="ml-1 text-success cursor-info"
                  size="17"
                  v-else
                />
                <span v-if="!data.item.client_account_id">
                  <br />
                  <b-badge variant="light-primary">{{
                    data.item.name_module
                  }}</b-badge>
                </span>
              </span>
              <b-badge variant="light-primary" style="margin-top: 7px">{{
                data.item.account
              }}</b-badge>
              <span style="margin-top: 7px">{{ data.item.mobile }}</span>

              <b-badge
                variant="light-info"
                class="cursor-pointer"
                style="margin-top: 7px"
                @click="
                  openQuestionnaireInformation(
                    data.item.id,
                    data.item.lead_id,
                    data.item.name_lead
                  )
                "
                >Questionnaire</b-badge
              >
            </div>
          </template>

          <template #cell(attorney_name)="data">
            <div
              v-b-tooltip.hover="'Case Number: ' + data.item.case_number"
              style="margin-bottom: 5px"
            >
              <strong> # {{ data.item.case_number }}</strong>
            </div>

            <div v-b-tooltip.hover="'Attorney'" style="margin-bottom: 5px">
              <feather-icon icon="UserIcon" size="15" />
              {{
                data.item.attorney_name !== null &&
                data.item.attorney_name !== undefined
                  ? data.item.attorney_name
                  : "-"
              }}
            </div>

            <div
              v-b-tooltip.hover
              :title="data.item.court_name == null ? 'State' : 'Court'"
              style="margin-bottom: 5px"
            >
              <feather-icon icon="HomeIcon" size="15" />
              {{
                data.item.court_name == null
                  ? data.item.state
                  : data.item.court_name
              }}
            </div>

            <div v-b-tooltip.hover="'Creditor'">
              <feather-icon icon="DollarSignIcon" size="15" />
              {{ data.item.credit_name }}
            </div>
          </template>

          <template #cell(document_date)="data">
            <div class="d-flex">
              <div class="d-flex flex-column" style="gap: 5px">
                <span
                  >{{ data.item.document_name }}
                  <feather-icon
                    v-b-tooltip.hover.top="'View Files'"
                    class="pointer text-warning"
                    style="margin-left: 5px"
                    icon="FileIcon"
                    size="15"
                    @click="openModalViewDocuments(data.item.id)"
                /></span>
                <div class="d-flex">
                  <b-badge
                    :variant="
                      data.item.service_id == 1
                        ? 'light-primary'
                        : 'light-warning'
                    "
                    class="mr-1"
                    >{{ data.item.service_name }}</b-badge
                  >
                </div>
                <span>{{ data.item.created_at | myGlobal }} </span>

                <span
                  class="text-success"
                  v-if="data.item.module_id != 5 && data.item.amount_pay != 0"
                >
                  $ {{ data.item.amount_pay | currencyZero }}
                  <feather-icon
                    v-if="
                      [1, 2, 17].includes(currentUser.role_id) &&
                      moduleId == 5 &&
                      data.item.hasPendingPayment > 0
                    "
                    style="margin-top: -3px"
                    icon="DollarSignIcon"
                    size="15"
                    class="cursor-pointer"
                    :class="
                      data.item.hasPendingPayment ? 'text-danger' : 'text-muted'
                    "
                    @click="openCiPendingPayments(data.item.id)"
                    v-b-tooltip.hover.top="
                      getTooltipPendingPayment(data.item.hasPendingPayment)
                    "
                  />
                </span>
              </div>
            </div>
          </template>

          <template #cell(id)="data">
            <div>
              <b-button
                size="sm"
                variant="outline-primary"
                class="mt-1 w-100 d-flex justify-content-center align-items-center gap-2"
                @click="openModalTimeline(data.item.id)"
              >
                <feather-icon
                  icon="GitCommitIcon"
                  size="17"
                  style="margin-right: 5px"
                />
                Timeline
              </b-button>
            </div>
          </template>

          <template #cell(assigned_to)="data">
            <div v-if="data.item.advisor_id !== null">
              <div class="text-center">{{ data.item.assigned_to }}</div>
              <div
                class="d-flex align-items-center justify-content-center"
                style="margin: 4px 0px"
              >
                <feather-icon
                  v-if="
                    [1, 2, 17].includes(currentUser.role_id) &&
                    moduleId == 5 &&
                    data.item.status !== 'DONE'
                  "
                  class="pointer text-primary mr-1"
                  icon="EditIcon"
                  size="15"
                  @click="openModalAssignCase(data.item.id)"
                />
                <feather-icon
                  icon="ListIcon"
                  class="cursor-pointer text-info"
                  size="15"
                  v-b-tooltip.hover.left="'Open Tracking'"
                  @click="openModalTrackingAdvisor(data.item.id)"
                />
              </div>
            </div>
            <div v-else>
              <b-badge variant="light-warning"> PENDING</b-badge>
            </div>
          </template>

          <template #cell(created_by)="data">
            {{ data.item.created_by }}
            <br />
            {{ data.item.created_at | myGlobalDay }}
            <span
              v-if="
                data.item.service_type === 'INVESTIGATION' &&
                data.item.status === 'PENDING' &&
                parseInt(data.item.time_to_expire) > 0
              "
              v-b-tooltip.hover.top="
                `Case expires in ${data.item.time_to_expire} hours`
              "
              class="font-weight-bold d-block text-left text-danger"
            >
              <feather-icon icon="ClockIcon" size="15" />
              {{ data.item.time_to_expire }}h</span
            >
          </template>

          <template #cell(last_note)="data">
            <span>
              <template v-if="data.item.last_note == null"> None </template>
              <template v-else>
                <strong class="mr-1">LT</strong>
                <b-badge
                  v-b-tooltip.hover.topright="'Last Tracking'"
                  variant="light-info"
                  >{{ data.item.last_note | myGlobalDay }}
                </b-badge>
              </template>

              <br />

              <template v-if="data.item.next_task != '-'">
                <strong class="mr-1">NT</strong>
                <b-badge
                  v-b-tooltip.hover.topright="
                    'Next Task Due Date' +
                    (data.item.next_task_is_done == 1 ? ' (Done)' : ' (Undone)')
                  "
                  :variant="getVariantNT(data.item.next_task)"
                >
                  <span
                    v-if="getVariantNT(data.item.next_task) == 'light-primary'"
                  >
                    Today
                  </span>
                  <span v-else>
                    {{ data.item.next_task | myGlobalDay }}
                  </span>
                </b-badge>
              </template>
            </span>

            <div
              class="d-flex align-items-center justify-content-center"
              style="margin: 4px 0px"
            >
              <feather-icon
                v-if="
                  data.item.status !== 'PENDING' &&
                  data.item.advisor_id !== null &&
                  moduleId == 5 &&
                  data.item.status !== 'DONE'
                "
                class="cursor-pointer text-primary mr-1"
                icon="PlusCircleIcon"
                size="16"
                @click="openAddTrackingCaseModal(data.item.id)"
              />
              <feather-icon
                v-if="
                  data.item.status !== 'PENDING' &&
                  data.item.advisor_id !== null &&
                  data.item.last_note !== null
                "
                class="cursor-pointer text-info mr-1"
                icon="ListIcon"
                size="16"
                @click="openTrackingCaseModal(data.item.id)"
              />
              <b-badge
                v-if="data.item.pending_tasks > 0"
                variant="light-warning"
                v-b-tooltip.hover.topright="'Pending Tasks'"
              >
                {{ data.item.pending_tasks }}
              </b-badge>
            </div>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="getVariant(data.item.status)"
              >STATUS: {{ data.item.status }}</b-badge
            >
            <br />
            <b-badge
              :variant="'light-' + getVariantStep(data.item.last_cur_step_id)"
              class="mt-1"
              v-b-tooltip.hover.left
              :title="data.item.last_cur_step"
            >
              STEP: {{ data.item.last_cur_step | limitChars(15) }}
              <feather-icon
                class="ml-25"
                :icon="getIconStep(data.item.last_cur_step_id)"
                size="16"
              />
            </b-badge>
            <br />
            <b-badge
              v-if="isGlobalSearch"
              :variant="'light-warning'"
              class="mt-1"
              v-b-tooltip.hover.left
              :title="data.item.tab"
            >
              TAB: {{ data.item.tab }}
            </b-badge>
            <br v-if="isGlobalSearch" />
            <b-badge
              v-if="data.item.last_cur_step_id == 2"
              variant="info"
              class="mt-1"
              >NEW CASE</b-badge
            >
          </template>

          <template #cell(actions)="data">
            <div>
              <b-button
                size="sm"
                variant="outline-primary"
                class="mt-1 w-100"
                @click="openReconfirmModal(data.item.id, true)"
              >
                View Case
              </b-button>

              <b-button
                v-if="
                  data.item.status === 'PENDING' &&
                  [1, 2, 17].includes(currentUser.role_id) &&
                  moduleId == 5
                "
                size="sm"
                variant="outline-warning"
                class="mt-1 w-100"
                @click="openReconfirmModal(data.item.id, false)"
              >
                Validate Case
              </b-button>

              <b-button
                v-if="
                  (data.item.sent_status === 'SENT' ||
                    data.item.service_type === 'INVESTIGATION') &&
                  data.item.status == 'REVISION' &&
                  moduleId == 5
                "
                size="sm"
                variant="outline-success"
                class="mt-1 w-100"
                @click="openUpdateCaseDocuments(data.item.id)"
              >
                Update Case
              </b-button>

              <b-button
                v-if="
                  data.item.advisor_id !== null &&
                  data.item.service_id === 1 &&
                  moduleId == 5 &&
                  data.item.status !== 'DONE' &&
                  data.item.status !== 'PENDING' &&
                  data.item.sent_status == 'PREPARE ANSWER'
                "
                size="sm"
                variant="outline-info"
                class="mt-1 w-100"
                @click="
                  openModalPreparedAnswer(data.item.id, data.item.sent_status)
                "
              >
                Prepare Answer
              </b-button>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>

    <assign-case-modal
      v-if="modalAssignCaseShow"
      :caseId="caseId"
      @close="modalAssignCaseShow = false"
      @closing="refreshTable"
    />

    <view-documents-show-modal
      v-if="modalViewDocumentsShow"
      :caseId="caseId"
      @close="modalViewDocumentsShow = false"
    />

    <ValidateCaseModal
      v-if="modalReconfirmarCaseShow"
      :caseId="caseId"
      :isOnlyView="isOnlyView"
      @close="modalReconfirmarCaseShow = false"
      @closing="updateAndCloseReconfirmModal"
      @reloadTable="refreshTable"
    />

    <CaseTrackingModal
      v-if="showTrackingCaseModal"
      :caseId="caseId"
      @close="showTrackingCaseModal = false"
      @closewithrefresh="closeWithRefreshTrackingCaseModal()"
      @refresh="refreshTable"
    />

    <prepared-answer-modal
      v-if="modalPreparedAnswer"
      @onlyClose="modalPreparedAnswer = false"
      :case-id="caseId"
      :read-only="readOnly"
      @completeStep="refreshTable"
      @completeAnswer="(modalPreparedAnswer = false), refreshTable()"
    ></prepared-answer-modal>

    <TrackingAssingCaseModal
      v-if="modalTrackingAssingCaseShow"
      :caseId="caseId"
      @close="modalTrackingAssingCaseShow = false"
    />

    <modal-tracking-case
      v-if="modalTrackingCaseModal"
      :caseId="caseId"
      @close="modalTrackingCaseModal = false"
    />

    <timelineByCase
      v-if="timelineShow"
      :caseId="caseId"
      @close="timelineShow = false"
    />

    <ModalUpdateCaseDocuments
      v-if="showUpdateCaseDocuments"
      :caseId="caseId"
      @close="showUpdateCaseDocuments = false"
      @closewithrefresh="(showUpdateCaseDocuments = false), refreshTable()"
    />

    <TotalAmountDetailCiModal
      v-if="showTotalAmountDetailCiModal"
      :tab="tab"
      :mode="detailMode"
      @close="showTotalAmountDetailCiModal = false"
    />

    <available-days-modal
      v-if="showModalAvailableDays"
      :itemCase="this.itemCase"
      @close="showModalAvailableDays = false"
    />

    <InputIndicationModal
      v-if="showAddTrackingCaseModal"
      :caseId="caseId"
      @close="showAddTrackingCaseModal = false"
      @refresh="refreshTable()"
    />

    <CiPendingPaymentsModal
      v-if="showCiPendingPaymentsModal"
      :caseId="caseId"
      @close="showCiPendingPaymentsModal = false"
      @closeWithRefresh="(showCiPendingPaymentsModal = false), refreshTable()"
    />

    <b-modal
      :title="'Questionnaire of ' + leadName"
      size="xmd"
      v-model="showQuestionnaire"
      @hide="showQuestionnaire = false"
      scrollable
    >
      <questionnaire-info
        :lead-id="leadId"
        :case-id="caseId"
        :read-only="true"
      ></questionnaire-info>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DownloadFormats from "@/views/debt-solution/views/court-info/components/modals/DownloadTypeDocument.vue";
import FieldsCi from "@/views/debt-solution/views/court-info/components/data/fields.data.js";
import FiltersCi from "@/views/debt-solution/views/court-info/components/data/filters.data";
import ClientService from "@/views/commons/components/clients/services/clients.services";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import Steps from "@/views/debt-solution/views/court-info/components/data/steps.js";
import AssignCaseModal from "@/views/debt-solution/views/court-info/components/modals/AssignCaseModal.vue";
import ViewDocumentsShowModal from "@/views/debt-solution/views/court-info/components/modals/ViewDocumentsShowModal.vue";
import ValidateCaseModal from "@/views/debt-solution/views/court-info/components/modals/ValidateCaseModal.vue";
import PreparedAnswerModal from "@/views/debt-solution/views/court-info/components/modals/PreparedAnswerModal.vue";
import CaseTrackingModal from "@/views/debt-solution/views/court-info/components/modals/TrackingCaseModal.vue";
import TrackingAssingCaseModal from "@/views/debt-solution/views/court-info/components/modals/TrackingAssingCaseModal.vue";
import ModalTrackingCase from "@/views/debt-solution/views/court-info/components/modals/modalTrackingCase.vue";
import DebtSolutionService from "@/views/debt-solution/services/debt-solution.service.js";
import ModalUpdateCaseDocuments from "@/views/debt-solution/views/court-info/components/modals/ModalUpdateCaseDocuments.vue";
import timelineByCase from "@/views/debt-solution/views/court-info/components/modals/TimelineShowModal.vue";
import TotalAmountDetailCiModal from "@/views/debt-solution/views/court-info/components/modals/TotalAmountDetailCIModal.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import AvailableDaysModal from "@/views/debt-solution/views/court-info/components/modals/AvailableDaysModal.vue";
import InputIndicationModal from "@/views/debt-solution/views/court-info/components/modals/GiveIndicationsModal.vue";
import moment from "moment";
import CiPendingPaymentsModal from "@/views/debt-solution/views/court-info/components/modals/CiPendingPayments.vue";
import QuestionnaireInfo from "@/views/debt-solution/views/court-info/components/modals/CiQuestionnaireModal.vue";

export default {
  props: {
    searchText: {
      type: String,
      default: null,
    },
    isGlobalSearch: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidateCaseModal,
    DownloadFormats,
    AssignCaseModal,
    ViewDocumentsShowModal,
    PreparedAnswerModal,
    CaseTrackingModal,
    TrackingAssingCaseModal,
    ModalTrackingCase,
    timelineByCase,
    ModalUpdateCaseDocuments,
    TotalAmountDetailCiModal,
    FeatherIcon,
    AvailableDaysModal,
    InputIndicationModal,
    CiPendingPaymentsModal,
    QuestionnaireInfo,
  },
  data() {
    return {
      today: moment().format("YYYY-MM-DD"),
      showTotalAmountDetailCiModal: false,
      totalAmount: 0,
      totalPendingAmount: 0,
      fields: FieldsCi,
      filters: FiltersCi,
      selectedTab: 1,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by client name or account or mobile...",
        model: "",
      },
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      searchInput: "",
      items: [],
      caseSelected: null,
      STEPS: Steps,
      modalReconfirmarCaseShow: false,
      showModal: false,
      modalViewDocumentsShow: false,
      hasAnswer: {
        value: 0,
      },
      modalAssignCaseShow: false,
      caseId: null,
      leadId: null,
      leadName: "",
      modalPreparedAnswer: false,
      showUpdateCaseDocuments: false,
      showTrackingCaseModal: false,
      modalTrackingAssingCaseShow: false,
      readOnly: false,
      modalTrackingCaseModal: false,
      timelineShow: false,
      showAddTrackingCaseModal: false,
      stepsGrid: [
        {
          id: 1,
          icon: "FilePlusIcon",
          color: "primary",
        },
        {
          id: 2,
          icon: "CheckIcon",
          color: "success",
        },
        {
          id: 3,
          icon: "CalendarIcon",
          color: "warning",
        },
        {
          id: 4,
          icon: "ArchiveIcon",
          color: "primary",
        },
        {
          id: 5,
          icon: "FlagIcon",
          color: "success",
        },
        {
          id: 6,
          icon: "TrelloIcon",
          color: "primary",
        },
        {
          id: 7,
          icon: "ThumbsUpIcon",
          color: "success",
        },
        {
          id: 100,
          icon: "FileTextIcon",
          color: "info",
        },
        {
          id: 101,
          icon: "UploadIcon",
          color: "info",
        },
        {
          id: 102,
          icon: "MailIcon",
          color: "info",
        },
        {
          id: 103,
          icon: "MailIcon",
          color: "warning",
        },
        {
          id: 104,
          icon: "MailIcon",
          color: "warning",
        },
      ],
      showModalAvailableDays: false,
      itemCase: [],
      showCiPendingPaymentsModal: false,
      isOnlyView: null,
      detailMode: null,
      showQuestionnaire: false,
    };
  },
  async created() {
    this.usersAllDebtSolution();
    this.getListModules();
    if (this.moduleId != 5) {
      this.usersAllPrograms();
    }
    this.socketAssignedCase();
    this.getAllCaseSteps();
    this.getAllServiceTypeCi();
  },
  watch: {
    getFilterServices(newVal) {
      if (newVal == null) {
        this.filters[5].visible = false;
        this.filters[5].model = null;
      } else {
        this.getAllDocumentTypeCi(this.filters[4].model);
        this.filters[5].visible = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    tab() {
      if (this.searchText) return null;
      return this.$route.matched[2].meta.tab;
    },
    totalPay() {
      if (this.items.length > 0) {
        return this.items[0].total_pay;
      } else {
        return "0.00";
      }
    },
    dashboardRouteName() {
      const routers = {
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        12: "paragon-dashboard",
        14: "bookeeping-dashboard",
        16: "management-dashboard",
        18: "quality-client-dashboard",
        20: "connection-clients-account",
        21: "ce-customer-service-dashboard",
        22: "ce-customer-service-dashboard",
        25: "specialist-digital-dashboard",
        16: "management-client-dashboard",
      };
      return routers[this.moduleId] || null;
    },
    dashboardLeadRouteName() {
      const routers = {
        2: "lead-show",
        3: "bussiness-lead-show",
        4: "administration-lead-show",
        5: "debtsolution-lead-show",
        6: "creditexperts-lead-show",
        7: "boostcredit-lead-show",
        8: "tax-research-lead-show",
        10: "claimdepartment-lead-show",
        11: "specialists-leads-show",
        12: "ti-lead-show",
        14: "bookeeping-lead-show",
        20: "connection-lead-show",
        22: "customerservice-lead-show",
        23: "financial-leads-lead-show",
        25: "specialist-dg-lead-show",
        26: "sales-lead-show",
      };
      return routers[this.moduleId] || null;
    },
    visibleFilters() {
      if (this.moduleId == 5) {
        this.filters[8].visible = true;
      } else {
        this.filters[8].visible = false;
      }
      if (this.moduleId != 5 || [1, 2, 17].includes(this.currentUser.role_id)) {
        this.filters[6].visible = true;
      } else {
        this.filters[6].visible = false;
      }
      if (
        (this.moduleId != 5 && [1, 2, 17].includes(this.currentUser.role_id)) ||
        this.filters[8].model != null
      ) {
        this.filters[9].visible = true;
        this.usersAllPrograms();
      } else {
        this.filters[9].model = null;
        this.filters[9].visible = false;
      }
      return this.filters.filter((column) => column.visible);
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
    getFilterServices() {
      return this.filters[4].model;
    },
  },
  methods: {
    openCiPendingPayments(caseId) {
      this.showCiPendingPaymentsModal = true;
      this.caseId = caseId;
    },
    closeCiPendingPaymentsModal() {
      this.showCiPendingPaymentsModal = false;
    },
    getVariantNT(nextTracking) {
      let nextTrackingDate = moment(nextTracking).format("YYYY-MM-DD");
      let today = moment().format("YYYY-MM-DD");
      if (today == nextTrackingDate) {
        return "light-primary";
      } else if (today > nextTrackingDate) {
        return "light-danger";
      } else {
        return "light-secondary";
      }
    },
    getTooltipPendingPayment(pendingPayments) {
      if (pendingPayments > 0) {
        return `This case has ${pendingPayments} pending payment(s)`;
      } else {
        return `This case has no pending payments`;
      }
    },
    openModalAvailableDays(item) {
      this.itemCase = item;
      this.showModalAvailableDays = true;
    },
    openUpdateCaseDocuments(caseId) {
      this.caseId = caseId;
      this.showUpdateCaseDocuments = true;
    },
    openTrackingCaseModal(caseId) {
      this.caseId = caseId;
      this.showTrackingCaseModal = true;
    },
    closeWithRefreshTrackingCaseModal() {
      this.showTrackingCaseModal = false;
      this.refreshTable();
    },
    refreshTable() {
      this.$refs["refSearchCourtInfo"].refresh();
    },
    async CiProvider() {
      this.isBusy = true;
      const params = {
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
        module_id: this.moduleId,
        assigned_to: this.filters[6].model,
        search: this.isGlobalSearch
          ? this.searchText
          : this.filterPrincipal.model,
        from: this.filters[0].model,
        to: this.filters[1].model,
        perPage: this.paginate.perPage,
        currentPage: this.paginate.currentPage,
        tab: this.isGlobalSearch ? null : this.tab,
        status: this.filters[7].model,
        fil_module_id: this.filters[8].model,
        created_by: this.filters[9].model,
        step_id: this.filters[10].model,
        last_note_from: this.filters[2].model,
        last_note_to: this.filters[3].model,
        type_service: this.filters[4].model,
        type_document: this.filters[5].model,
      };
      const { data, status } = await CourtInfoService.searchCourtInfo(params);

      data.data.forEach((row) => {
        let created_at = moment(row.created_at).format("YYYY-MM-DD HH:mm:ss");
        let validated_at = moment(row.validated_at).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        let differenceInHours = moment(validated_at).diff(created_at, "hours");
        let expired = differenceInHours > 24;

        if (
          (parseInt(row.time_to_expire) < 24 &&
            row.service_type == "INVESTIGATION" &&
            row.status == "PENDING") ||
          (expired == true &&
            row.status != "PENDING" &&
            row.service_type == "INVESTIGATION")
        ) {
          {
            row._cellVariants = { name_lead: "expired" };
          }
        }
      });

      if (status === 200) {
        this.items = data.data;
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        if (this.items.length > 0) {
          this.totalAmount = this.items[0].total_pay;
          this.totalPendingAmount = this.items[0].total_pending_to_pay;
        }
        return this.items || [];
      }
      this.isBusy = false;
    },
    async getAllServiceTypeCi() {
      try {
        const response = await CourtInfoService.getAllServiceTypeCi();
        this.filters[4].options = response.map((val) => {
          return {
            label: val.name,
            value: val.id,
          };
        });
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong searching all Supervisors!"
        );
      }
    },
    async getAllDocumentTypeCi(type) {
      try {
        const response = await CourtInfoService.getAllDocumentTypeCi({
          service_type_id: type,
        });
        this.filters[5].options = response.map((val) => {
          return {
            label: val.name,
            value: val.id,
          };
        });
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong searching all Supervisors!"
        );
      }
    },
    async usersAllPrograms() {
      try {
        const module =
          this.moduleId == 5 ? this.filters[8].model : this.moduleId;
        const data = await DebtSolutionService.getAdvisors(module);
        this.filters[9].options = data.data.map((val) => {
          return {
            label: val.value,
            value: val.id,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    async usersAllDebtSolution() {
      try {
        const data = await ClientService.usersAllPrograms({
          id: 5,
        });
        this.filters[6].options = data.data.map((val) => {
          return {
            label: val.user_name,
            value: val.id,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    async openModalViewDocuments(id) {
      this.caseId = id;
      this.modalViewDocumentsShow = true;
    },

    openModalPreparedAnswer(id, sent) {
      this.caseId = id;
      this.readOnly = sent == "SENT" || sent == "IN CORRESPONDENCE";
      this.modalPreparedAnswer = true;
    },

    openModalAssignCase(id) {
      this.caseId = id;
      this.modalAssignCaseShow = true;
    },

    openReconfirmModal(id, isOnlyView) {
      this.caseId = id;
      this.modalReconfirmarCaseShow = true;
      this.isOnlyView = isOnlyView;
    },

    updateAnswerCase(answer) {
      this.hasAnswer = answer;
      this.modalReconfirmarCaseShow = false;
    },
    updateAndCloseReconfirmModal() {
      this.modalReconfirmarCaseShow = false;
      this.refreshTable();
    },

    openModalTrackingAdvisor(id) {
      this.caseId = id;
      this.modalTrackingAssingCaseShow = true;
    },

    openAddTrackingCaseModal(id) {
      this.caseId = id;
      this.showAddTrackingCaseModal = true;
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "awesome") return "table-success";
    },

    getVariant(status) {
      switch (status) {
        case "PENDING":
          return "light-warning";
        case "REVISION":
          return "light-secondary";
        case "APPROVED":
          return "light-primary";
        case "CANCELLED":
        case "REJECTED":
          return "light-danger";
        case "DONE":
          return "light-success";
        case "CLOSED":
          return "light-info";
      }
    },

    getIconStatus(status) {
      switch (status) {
        case "IN CORRESPONDENCE":
          return "GitCommitIcon";
        case "SENT":
          return "SendIcon";
        default:
          return "AlertTriangleIcon";
      }
    },
    openModalTracking(id) {
      this.caseId = id;
      this.modalTrackingCaseModal = true;
    },
    openModalTimeline(id) {
      this.caseId = id;
      this.timelineShow = true;
    },
    getIconStep(step) {
      return this.stepsGrid.find((item) => item.id == step).icon;
    },
    getVariantStep(step) {
      return this.stepsGrid.find((item) => item.id == step).color;
    },
    async getListModules() {
      try {
        const response = await amgApi.get("/module/get-modules");

        this.filters[8].options = response.data.map((val) => {
          return {
            label: val.name,
            value: val.id,
          };
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getAllCaseSteps() {
      try {
        const { data } = await CourtInfoService.getAllCaseSteps();
        this.filters[10].options = data.map((val) => {
          return {
            label: val.name,
            value: val.id,
          };
        });
      } catch (error) {}
    },
    // socketNewCase() {
    //   window.socket.subscribe("debtsolution-notifications");

    // },
    socketAssignedCase() {
      window.socket.subscribe("debtsolution-notifications");
      window.socket.bind(
        "reconfirm-cases-notification-refresh",
        async (response) => {
          const { case: caseObject, old_advisor_id } = response;
          if (
            caseObject[0].advisor_id == this.currentUser.user_id ||
            old_advisor_id == this.currentUser.user_id
          ) {
            this.refreshTable();
          }
        }
      );
      window.socket.bind("new-cases-notification-refresh", async (response) => {
        if (this.currentUser.role_id == 2) {
          this.refreshTable();
        }
      });
    },

    openQuestionnaireInformation(case_id, lead_id, name_lead) {
      this.leadId = lead_id;
      this.caseId = case_id;
      this.leadName = name_lead;
      this.showQuestionnaire = true;
    },
  },
  beforeDestroy() {
    new Promise((resolve, reject) => {
      try {
        window.socket.unbind("reconfirm-cases-notification-refresh");
        window.socket.unbind("new-cases-notification-refresh");
        resolve(true);
      } catch (error) {
        reject(false);
        console.log(error);
      }
    });
  },
};
</script>


<style lang="scss">
.table-expired,
.table-expired > td {
  border-left: 5px solid #f44336 !important;
}
.ci-table {
  td.client-name {
    padding-right: 5px;
  }
}
</style>
